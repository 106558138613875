<style lang="less" scoped>
  .org-block {
    display: flex;
    justify-content: flex-start;
    margin-top: 10px;
    flex-wrap: wrap;
  }
  .org-item {
    width: 30%;
    margin: 0 5px;
  }
  .org-title {
    margin-bottom: 10px;
  }
</style>

<template>
  <div class="purchase-form">
    <fm-form>
      <fm-form-item label="年份">
        <fm-date-picker v-verifier required v-model="value.year" type="year" placeholder="请选择年份"></fm-date-picker>
      </fm-form-item>
    </fm-form>
    <fm-form>
      <fm-form-item label="批次">
        <fm-input-new v-verifier required v-model="value.batch" placeholder="请输入批次"></fm-input-new>
      </fm-form-item>
    </fm-form>
    <div class="org-block">
      <div class="org-item">
        <div class="org-title">职能科室:</div>
        <org-choose v-model="value.busOrgIds" />
      </div>
      <div class="org-item">
        <div class="org-title">提报科室:</div>
        <org-choose v-model="value.submitOrgIds" />
      </div>
      <div class="org-item">
        <div class="org-title">分管领导:</div>
        <worker-choose v-model="value.purchaseMethodLeadWorkerIds" />
      </div>
      <div class="org-item">
        <div class="org-title">院长:</div>
        <worker-choose v-model="value.deanWorkerIds" />
      </div>
    </div>
  </div>
</template>

<script>
import OrgChoose from '../org.vue'
import WorkerChoose from '../worker.vue'

function getDefault () {
  return {
    year: new Date(),
    batch: '',
    busOrgIds: [],
    submitOrgIds: [],
    purchaseMethodLeadWorkerIds: [],
    deanWorkerIds: []
  }
}

function transData (data) {
  return JSON.parse(JSON.stringify(data, function (key, value) {
    if (['busOrgIds', 'submitOrgIds', 'purchaseMethodLeadWorkerIds', 'deanWorkerIds'].includes(key)) {
      return value ? value.split(',').map(v => Number(v)) : []
    } else {
      return value
    }
  }))
}

export default {
  components: { OrgChoose, WorkerChoose },
  props: {
    data: { type: Object, defualt: () => null }
  },
  data () {
    return {
      value: getDefault()
    }
  },
  watch: {
    data: {
      deep: true,
      handler () {
        this.value = this.data ? transData(this.data) : getDefault()
      },
      immediate: true
    }
  },
  methods: {
    getData () {
      return JSON.parse(JSON.stringify(this.value, (key, value) => {
        if (key === 'year') {
          return value ? Number(this.$datetime.format(value, 'Y')) : null
        } else if (Array.isArray(value)) {
          return value.join(',')
        } else if (value instanceof Date) {
          return this.$datetime.format(value, 'Y-m-d H:i:s')
        } else {
          return value
        }
      }))
    }
  },
}
</script>
